<template>
  <div v-if="validKey">
    <PageHeader title="Transactie goedkeuring" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <div class="tw-mb-8 tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-shadow-lg">
        <table class="tw-mb-4">
          <h2>Transactiedetails</h2>
          <tr class="tw-text-sm">
            <th>Referentie</th>
            <th>Adres</th>
          </tr>
          <tr>
            <td>
              <a :href="`/transaction/view/${approval.transaction_id}`">
                {{ approval.reference || '-' }}
              </a>
            </td>
            <td>{{ approval.address || '-' }}</td>
          </tr>
        </table>

        <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
          <h2 class="tw-inline-block">Details goedkeuring</h2>
          <button
            v-if="key"
            :disabled="approval.approved_on"
            class="action tw-bg-primary"
            @click="approveTransaction"
          >
            <i class="far fa-check"/> Goedkeuren
          </button>
        </div>
        <table>
          <tr class="tw-text-sm">
            <th>Medewerker</th>
            <th>Commissiebedrag</th>
            <th>Status</th>
          </tr>
          <tr>
            <td>{{ (approval.collaborator && approval.collaborator.display_name) || '-' }}</td>
            <td>{{ currency(approval.amount) }}</td>
            <td>
              {{ approval.approved_on ? 'Goedgekeurd' : 'Goedkeuring vereist' }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <h2 v-else class="tw-text-danger">
    Ongeldige link om deze transactie goed te keuren.
  </h2>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import { getTransactionApprovalDetails, updateTransactionApprovalDetails } from '@/services/transactions'
import { startLoadingModal, questionModal, successModal, errorModal } from '@/modalMessages'
import { currency } from '@/utils/helpers'

export default {
  name: 'TransactionApproval',
  components: {
    PageHeader
  },
  props: {
    transactionId: {
      type: [Number, String],
      required: true
    },
    approvalId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      approval: {}
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler () {
        return this.fetchApprovalDetails()
      }
    }
  },
  computed: {
    key () {
      return this.$route.query.key
    },
    validKey () {
      return this.key ? this.key === this.approval?.key : true
    }
  },
  methods: {
    currency,
    async fetchApprovalDetails () {
      try {
        this.approval = {} // To clear the approval data and not show any from the previous call if current call fails
        const response = await getTransactionApprovalDetails(this.transactionId, this.approvalId)
        this.approval = response.data
        if (!this.validKey) return errorModal('Ongeldige link om deze transactie goed te keuren.')
        return response
      } catch (error) {
        errorModal('Fout bij ophalen van goedkeuringsdetails, probeer het opnieuw.')
      }
    },
    async approveTransaction () {
      try {
        const result = await questionModal('Ben je zeker dat je deze transactie wil goedkeuren?')
        if (!result.value) return

        startLoadingModal('Transactie goedkeuren...')
        const payload = {
          approved_on: new Date()
        }
        const response = await updateTransactionApprovalDetails(this.transactionId, this.approvalId, payload)
        await this.fetchApprovalDetails()
        successModal('Transactie is goedgekeurd')
        return response
      } catch (error) {
        errorModal('Fout bij goedkeuren van transactie, probeer het opnieuw.')
      }
    }
  }
}
</script>

<style scoped>
table {
  @apply tw-w-full;
  @apply tw-table-auto;
  @apply tw-border-collapse;
}
th, td {
  @apply tw-pr-2;
  @apply tw-py-1;
}
</style>
